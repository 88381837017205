import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
  {
      id       : 'cart',
      title    : '',
      type     : 'item',
      icon     : 'shopping_cart',
      url      : '/apps/cart',
      exactMatch: false
  },
  {
    id: 'orders',
    title: 'Ordini',
    translate: 'NAVIGATION.VALGARDA.ORDERS.TITLE',
    type: 'item',
    url: '/apps/order/orders',
    exactMatch: false
  },
  {
    id: 'products',
    title: 'Prodotti',
    translate: 'NAVIGATION.VALGARDA.PRODUCTS.TITLE',
    type: 'item',
    url: '/apps/catalog/products',
    exactMatch: false
  },
  {
    id: 'documentation',
    title: 'Documentazione',
    translate: 'NAVIGATION.VALGARDA.DOCUMENTS.TITLE',
    type: 'item',
    url: '/apps/documentation',
    exactMatch: false
  },
  {
    id: 'tasks',
    title: 'Rapporti di intervento',
    translate: 'NAVIGATION.VALGARDA.TASKS.TITLE',
    type: 'item',
    url: '/apps/reports',
    exactMatch: false
  }
];
