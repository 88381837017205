export const environment = {
    production                  : true,
    hmr                         : false,
    apiUrl                      : 'https://test-be.valgarda.it/api/v1',
    tablePageSizeOptions        : [10,25,50,100,200],
    languages                   : ['it','en'],
    version                     : '1.1',
    oldVersion                  : '',
    versionCheckURL             : '',
    impersonateBaseUrl          : '',
    cookieUrl                   : ''
};
