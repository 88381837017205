import { Injectable } from '@angular/core';
import { DataService } from 'app/common/services/data.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiResponse } from 'app/common/_models/api-response';
import { CartItemAddInterface } from 'app/common/_models/cart/cart-item-add';
import { CartItemUpdateInterface } from 'app/common/_models/cart/cart-item-update';
import { CartComplete } from 'app/common/_models/cart/cart-complete';

@Injectable({
  providedIn: 'root'
})
export class CartListService extends DataService implements Resolve<any> {

  routeParams: any;

  cart: any;

  onCartChanged: BehaviorSubject<any>;

  constructor(http: HttpClient) {
    super(http, '/carts');

    // Set the defaults
    this.onCartChanged = new BehaviorSubject({});
  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.routeParams = route.params;

    return new Promise((resolve, reject) => {

      Promise.all([
        this.getCurrentCart()
      ]).then(
        () => {
          resolve();
        },
        reject
      );
    });
  }

  getCurrentCart(): Promise<any> {
    return new Promise((resolve, reject) => {
      super.getWithoutCatchError("current")
        .subscribe((response: ApiResponse) => {
          if (response.data) {
            this.cart = response.data;
            this.onCartChanged.next(this.cart);
            resolve(response);
          }
        }, reject);
    })
  };

  addItems(items: CartItemAddInterface[]): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this._serviceApiUrl + '/items', items)
        .subscribe((response: ApiResponse) => {
          this.getCurrentCart();
          resolve(response.data);
        }, reject);
    });
  }

  updateItem(itemId: number, item: CartItemUpdateInterface): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.put(this._serviceApiUrl + '/items/' + itemId + '/quantity', item)
        .subscribe((response: ApiResponse) => {
          this.cart = response.data;
          this.onCartChanged.next(this.cart);
          resolve(response.data);
        }, reject);
    });
  }

  deleteItem(itemId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.delete(this._serviceApiUrl + '/items/' + itemId)
        .subscribe((response: any) => {
          this.getCurrentCart();
          this.onCartChanged.next(this.cart);
          resolve(this.cart);
        }, reject);
    });
  }

  deleteCurrentCart(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.delete(this._serviceApiUrl + '/current')
        .subscribe((response: any) => {
          this.getCurrentCart();
        }, reject);
    });
  }

  completeCurrentCart(cart: CartComplete): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this._serviceApiUrl + '/complete', cart)
        .subscribe((response: ApiResponse) => {
          this.getCurrentCart(); // Per azzerare i contatori
          resolve(response.data);
        }, reject);
    });
  }

}
