import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalSettingsService {

  constructor() { }

  getLanguage() : string {
    if (localStorage){
        return localStorage['language'] || "it";
    }
    else
    {
        return "it";
    }
  }

  setLanguage(language: string) : void {
    if (localStorage){
        localStorage['language'] = language;
    }
  }
}
