import { Component } from '@angular/core';

import { locale as english} from './i18n/en';
import { locale as italian} from './i18n/it';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

@Component({
    selector   : 'footer',
    templateUrl: './footer.component.html',
    styleUrls  : ['./footer.component.scss']
})
export class FooterComponent
{
    /**
     * Constructor
     */
    constructor(
      private _fuseTranslationLoaderService: FuseTranslationLoaderService
    )
    {

      // Load the translations
      this._fuseTranslationLoaderService.loadTranslations(english, italian);
    }
}
