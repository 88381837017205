import { FuseConfig } from '@fuse/types';

/**
 * Default Fuse Configuration
 *
 * You can edit these options to change the default options. All these options also can be
 * changed per component basis. See `app/main/pages/authentication/login/login.component.ts`
 * constructor method to learn more about changing these options per component basis.
 */

export const fuseConfig: FuseConfig = {
    // Color themes can be defined in src/app/app.theme.scss
    colorTheme       : 'theme-valgarda-new',
    customScrollbars : true,
    layout : {
        style    : 'horizontal-layout-1',
        width    : 'fullwidth',
        navbar   : {
            primaryBackground   : 'valgarda-new-100',
            secondaryBackground : 'valgarda-new-200',
            folded              : false,
            hidden              : false,
            position            : 'left',
            variant             : 'vertical-style-1'
        },
        toolbar : {
            customBackgroundColor : true,
            background            : 'valgarda-new-50',
            hidden                : false,
            position              : 'above'
        },
        footer : {
            customBackgroundColor : true,
            background            : 'fuse-black-custom-900',
            hidden                : false,
            position              : 'above-fixed'
        },
        sidepanel: {
            hidden  : true,
            position: 'right'
        }
    }
};
