import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private authenticationService: AuthenticationService,
        private _translateService: TranslateService,
        ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = this.authenticationService.currentUserValue;
        if (currentUser && currentUser.userToken) {
            request = request.clone({
                setHeaders: {
                    'X-Access-Token': `${currentUser.userToken}`
                }
            });
        }

        // add language header
        let currentLang: string = this._translateService.currentLang || '';
        if (currentLang) {
            request = request.clone({
                setHeaders: {
                    'language': `${currentLang}`
                }
            })
        }

        return next.handle(request);
    }
}
