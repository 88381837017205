export class DataSourceFilter {
    constructor() {}

    static filterDataSource(data: any, filter: any, arrayFields?: string[]): any {
        if ( filter === '' )
        {
            return data;
        }

        filter = filter.toLowerCase();

        if (!arrayFields)
        {
            return data.filter(itemObj => {
                return this.searchInObj(itemObj, filter);
            });
        }
        else
        {
            return data.filter(itemObj => {
                return this.searchInProperties(itemObj, filter, arrayFields);
            });
        }        
    }

    public static searchInProperties(itemObj: any, filter: any, arrayFields: string[]): boolean {
        for ( const prop in itemObj )
        {
            if ( !itemObj.hasOwnProperty(prop) )
            {
                continue;
            }

            if (!arrayFields.includes(prop))
            {
                continue;
            }

            const value = itemObj[prop];

            if ( typeof value === 'string' )
            {
                if ( this.searchInString(value, filter) )
                {
                    return true;
                }
            }
        }
    }

    public static searchInObj(itemObj, searchText): boolean
    {
        for ( const prop in itemObj )
        {
            if ( !itemObj.hasOwnProperty(prop) )
            {
                continue;
            }

            const value = itemObj[prop];

            if ( typeof value === 'string' )
            {
                if ( this.searchInString(value, searchText) )
                {
                    return true;
                }
            }

            else if ( Array.isArray(value) )
            {
                if ( this.searchInArray(value, searchText) )
                {
                    return true;
                }
            }

            if ( typeof value === 'object' )
            {
                if ( this.searchInObj(value, searchText) )
                {
                    return true;
                }
            }
        }
    }

    public static searchInArray(arr, searchText): boolean
    {
        for ( const value of arr )
        {
            if ( typeof value === 'string' )
            {
                if ( this.searchInString(value, searchText) )
                {
                    return true;
                }
            }

            if ( typeof value === 'object' )
            {
                if ( this.searchInObj(value, searchText) )
                {
                    return true;
                }
            }
        }
    }

    public static searchInString(value, searchText): any
    {
        return value.toLowerCase().includes(searchText);
    }
}