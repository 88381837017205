import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { first } from 'rxjs/operators';
import { UserLogin } from '../_models/users/user-login';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;
    let appName = route.data.appName ;
    let canAccess = this.canAccess(currentUser, appName);

    if (!canAccess)
    {
      // not logged in so redirect to login page with the return url
      this.router.navigate(['/login']);
      return false;
    }
    else
    {
      this.authenticationService.checkToken().pipe(first())
        .subscribe(
          data => {
            return true;
          },
          error => {
            console.log("errore")
            this.authenticationService.logout(true);

            //$window.location ="/";
            // not logged in so redirect to login page with the return url
            //this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return true;
          });
    }
    return true;
  }

  canAccess(currentUser: UserLogin, appName?: string) {
    let canAccess = false;
    if (currentUser)
    {
      if (appName)
      {
        if (currentUser.detail.authorizations)
        {
          let authoObj = JSON.parse(currentUser.detail.authorizations);
          authoObj.forEach(authorization => {
            if (authorization.section == appName) {
              canAccess = authorization.read || authorization.write;
            }
          });
        }
        else
        {
          canAccess = true;
          if(currentUser.detail.hasProducts == false)
          {
              if(appName == 'orders' || appName == 'products' || appName == 'cart')
              {
                  canAccess = false;
              }
          }
        }
      }
      else
      {
        console.log("APP name non trovato: " + appName);
        // non e' stato specificato il nome dell'APP, tutti possono accedere
        canAccess = true;
      }
    }
    return canAccess;
  }
}
