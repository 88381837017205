import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ApiResponse } from 'app/common/_models/api-response';
@Injectable({
  providedIn: 'root'
})
export class LabelsService extends DataService {

  /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
  constructor(
    private _httpClient: HttpClient
  ) {
    super(_httpClient, '/label');
  }

  getAllLabel(): Promise<any> {
    return new Promise((resolve, reject) => {
      super.getAll(null, '')
        .subscribe((response: ApiResponse) => {
          if (response.data) {
            resolve(response);
          }
        }, reject);
    });
  }

  getLabel(lang: string, platform: string, key: string): Promise<any> {
    return new Promise((resolve, reject) => {
      super.getWithoutCatchError(lang + "/" + platform + "/" + key)
        .subscribe((response: ApiResponse) => {
          if (response.data) {
            resolve(response);
          }
        }, reject);
    });
  }

  saveLabel(lang: string, platform: string, key: string, label: any): Promise<any> {
    return new Promise((resolve, reject) => {
      super.updateCustom(lang + "/" + platform + "/" + key, label)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  saveAllLabel(lang: string, label: any): Promise<any> {
    return new Promise((resolve, reject) => {
      super.updateCustom(lang + "/admin/all", label)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  importLabel(file, lang) {
    return new Promise((resolve, reject) => {
      super.postFile(file, lang + "/admin/import", 'importFile')
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

}
