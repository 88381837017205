import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

import { BackendApiService } from './backend-api.service';
import { map } from 'rxjs/operators';
import { UserLogin } from '../_models/users/user-login';

@Injectable({ providedIn: 'root' })
export class AuthenticationService extends BackendApiService
{
    private currentUserSubject: BehaviorSubject<UserLogin>;

    public currentUser: Observable<UserLogin>;

    constructor(http: HttpClient, private router: Router)
    {
        super(http, '/users');
        this.currentUserSubject = new BehaviorSubject<UserLogin>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): UserLogin
    {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string)
    {
        return this.http.post<any>(this._serviceApiUrl + "/login", { username, password })
            .pipe(map(user => {

                // login successful if there's a jwt token in the response
                if (user.data)
                {
                    user = user.data;
                    if (user && user.userToken)
                    {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem('currentUser', JSON.stringify(user));
                        this.currentUserSubject.next(user);
                    }
                }
                return user;
            }));
    }

    logout(reload?:boolean)
    {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);

        // redirect to login page
        this.router.navigate(['login']);
        //window.location.href = "/";
        //window.location.reload(true);
        if(reload)
        {
          location.reload();
        }

    }

    forgotPassword(username: string)
    {
        return this.http.post<any>(this._serviceApiUrl + "/forgot-password?console=myarea", { username })
            .pipe(map(user => {
                return user;
            }));
    }

    changePassword(password: string, token: string, data:string)
    {
        return this.http.post<any>(this._serviceApiUrl + "/change-password-by-token", { password,token,data })
            .pipe(map(user => {
                return user;
            }));
    }

    checkToken()
    {
        return this.http.get<any>(this._serviceApiUrl + "/check-token")
            .pipe(map(result => {
                return result;
            }));
    }

    userCanRead(appName: string){
      let result = false;
      if (this.currentUserSubject.value != null) {
          let authoObj = JSON.parse(this.currentUserSubject.value.detail.authorizations);
          authoObj.forEach(authorization => {
            if (authorization.section == appName) {
              result = authorization.read;
            }
          });
      }
      return result;
    }

    userCanWrite(appName: string){
      let result = false;
      if (this.currentUserSubject.value != null) {
          let authoObj = JSON.parse(this.currentUserSubject.value.detail.authorizations);
          authoObj.forEach(authorization => {
            if (authorization.section == appName) {
              result = authorization.write;
            }
          });
      }
      return result;
    }
}
