import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

export class BackendApiService {

  protected baseUrl: string = environment.apiUrl;
  protected _serviceApiUrl: string;

  /**
   * Initialize the base HTTP API service
   * @param http 
   * @param url optional, always starting with '/'
   */
  constructor(protected http: HttpClient, url?: string) {
    if (url)
      this._serviceApiUrl = `${this.baseUrl}${url}`;
    else
      this._serviceApiUrl = this.baseUrl;
  }

  /**
   * Service API URL setter
   * @param apiUrl
   */
  set serviceApiUrl(apiUrl: string) {
    this._serviceApiUrl = `${this.baseUrl}${apiUrl}`;
  }
}
