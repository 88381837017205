import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LabelsService } from 'app/common/services/label.service';

export interface Locale
{
    lang: string;
    data: Object;
}

@Injectable({
    providedIn: 'root'
})
export class FuseTranslationLoaderService
{
    /**
     * Constructor
     *
     * @param {TranslateService} _translateService
     */
    constructor(
        private _translateService: TranslateService,
        private _labelsService: LabelsService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Load translations
     *
     * @param {Locale} args
     */
    loadTranslations(...args: Locale[]): void
    {
        const locales = [...args];

        // STARTAPP: aggiunta la ricerca delle labels anche sul backend (perche' gestibili tramite al sezione dell'editor etichette)
        locales.forEach((locale) => {
            // use setTranslation() with the third argument set to true
            // to append translations instead of replacing them
            this._translateService.setTranslation(locale.lang, locale.data, true);

            for (var i in locale.data) {
              let platform = locale['platform'] ? locale['platform'] : 'myarea';
              this._labelsService.getLabel(locale.lang,platform,i)
              .then(response =>{
                //console.log(response.data)
                this._translateService.setTranslation(locale.lang, JSON.parse(response.data.label), true);
              })
            }
        });
    }

    loadTranslationCustom(data, lang): void
    {
      this._translateService.setTranslation(lang, data, true);
    }

    getTranslation()
    {
      return this._translateService.translations;
    }
}
