import { LocalSettingsService } from './../../../common/services/local-settings.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { locale as english} from './i18n/en';
import { locale as italian} from './i18n/it';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { AuthenticationService } from 'app/common/services/authentication.service';

import { environment } from 'environments/environment';
import { UserLogin } from 'app/common/_models/users/user-login';
import { Router } from '@angular/router';
import { Cart } from 'app/common/_models/cart/cart';
import { ToolbarService } from './toolbar.service';
import { CartListService } from 'app/main/apps/cart/cart-list/cart-list.service';

@Component({
    selector     : 'toolbar',
    templateUrl  : './toolbar.component.html',
    styleUrls    : ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy
{
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];

    currentCart: BehaviorSubject<Cart> = this._toolbarService.currentCart;

    // STARTAPP: variabile con le informazioni dell'utente loggato
    loggedUser: UserLogin;

    isProduction = environment.production;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService,
     * @param {AuthenticationService} _authService
     * @param {LocalSettingsService} _localSettingsService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private _router: Router,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _authService: AuthenticationService,
        private _localSettingsService: LocalSettingsService,
        private _cartService: CartListService,
        private _toolbarService: ToolbarService
    )
    {
        // Set the defaults
        this.userStatusOptions = [
            {
                'title': 'Online',
                'icon' : 'icon-checkbox-marked-circle',
                'color': '#4CAF50'
            },
            {
                'title': 'Away',
                'icon' : 'icon-clock',
                'color': '#FFC107'
            },
            {
                'title': 'Do not Disturb',
                'icon' : 'icon-minus-circle',
                'color': '#F44336'
            },
            {
                'title': 'Invisible',
                'icon' : 'icon-checkbox-blank-circle-outline',
                'color': '#BDBDBD'
            },
            {
                'title': 'Offline',
                'icon' : 'icon-checkbox-blank-circle-outline',
                'color': '#616161'
            }
        ];

        this.languages = [
          {
              id   : 'it',
              title: 'Italian',
              flag : 'it'
          }
            /*{
                id   : 'en',
                title: 'English',
                flag : 'us'
            },*/
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        // Load the translations
        this._fuseTranslationLoaderService.loadTranslations(english, italian);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {'id': this._translateService.currentLang});

        // STARTAPP: Read current logged in user
        this.loggedUser = this._authService.currentUserValue;

        // STARTAPP: load current cart
        if (this.loggedUser !== null) {
          this._cartService.getCurrentCart().then(
            res => { // Success
                // Aggiorna il carrello corrente
                this._toolbarService.reloadCurrentCart(res.data);
            }
          );
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void
    {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void
    {
        // Do your search here...
        console.log(value);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void
    {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);

        // Save selected language in local storage
        this._localSettingsService.setLanguage(lang.id);
    }

    /**
     * Logs out from app
     */
    logout(): void
    {
        this._authService.logout();
    }

    profile(): void
    {
        this._router.navigate(['/apps/profile']);
    }

    goToCurrentCart() {
      if (this.currentCart !== null) {
        let url = '/apps/cart';
        this._router.navigate([url]);
      }
    }
}
