import { Component, HostBinding, Input, ChangeDetectorRef } from '@angular/core';
import { FuseNavigationService } from '../../navigation.service';
import { Router } from '@angular/router';
import { Subject, merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector   : 'fuse-nav-horizontal-item',
    templateUrl: './item.component.html',
    styleUrls  : ['./item.component.scss']
})
export class FuseNavHorizontalItemComponent
{
    @HostBinding('class')
    classes = 'nav-item';

    @Input()
    item: any;

    // STARTAPP: variabile per visualizzare i nodi del menù in base alle autorizzazioni dell'operatore
    showItem: boolean;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     */
     constructor(
         private _changeDetectorRef: ChangeDetectorRef,
         private _fuseNavigationService: FuseNavigationService,
         private _router: Router
     )
     {
         // Set the private defaults
         this._unsubscribeAll = new Subject();
     }

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
         .subscribe(() => {

             // Mark for check
             this._changeDetectorRef.markForCheck();
             this.showItem = this._fuseNavigationService.canShowItem(this.item);
         });

         this.showItem = this._fuseNavigationService.canShowItem(this.item);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // STARTAPP: variabile per visualizzare i nodi del menù in base alle autorizzazioni dell'operatore
    isLinkActive(url): boolean {
       const queryParamsIndex = this._router.url.indexOf('?');
       const baseUrl = queryParamsIndex === -1 ? this._router.url : this._router.url.slice(0, queryParamsIndex);
       return baseUrl === url;
    }
}
