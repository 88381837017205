export const locale = {
    lang: 'en',
    data: {
        'FOOTER': {
            'DESCRIPTION_1': 'VALGARDA S.R.L. - Via E.Fermi, 1/B - 46040 Ponti sul Mincio MN - Tel 0376 809848 - email:info@valgarda.it',
            'DESCRIPTION_2': 'P.iva 02142260237 - REA MN-218120 - PEC:valgarda@legalmail.it - Capitale Sociale Versato: € 110.000,00',
            'ADDRESS': 'VALGARDA S.R.L. - Via E.Fermi, 1/B - 46040 Ponti sul Mincio MN',
            'PIVA': 'P.IVA: IT 02142260237',
            'PHONE': 'Tel. 0376 809848',
            'EMAIL': 'email: info@valgarda.it',
            'PEC': 'PEC: valgarda@legalmail.it',
        }
    }
};
