import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable()
export class CustomLoaderService {
    isLoading = new Subject<boolean>();
    count = 0;
    show() {
        this.count = this.count + 1;
        this.isLoading.next(true);
    }
    hide() {
        this.count = this.count - 1;
        if (this.count == 0) {
            this.isLoading.next(false);
        }
    }
}
