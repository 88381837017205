import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Subject } from 'rxjs';
import { CustomLoaderService } from 'app/common/services/custom-loader.service';

import { locale as english } from './i18n/en';
import { locale as italian } from './i18n/it';

@Component({
    selector: 'custom-loader',
    templateUrl: './custom-loader.component.html',
    styleUrls: ['./custom-loader.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CustomLoader implements OnInit {

    color = 'primary';
    mode = 'indeterminate';
    value = 50;
    isLoading: Subject<boolean> = this.loaderService.isLoading;

    constructor(
      private loaderService: CustomLoaderService
    ) { }

    ngOnInit() {

    }


}
