import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from './i18n/en';
import { locale as italian } from './i18n/it';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InfoDialogComponent implements OnInit {

  title: string;
  message: string;

  constructor(
    public matDialogRef: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
  ) { }

  ngOnInit() {
    // Load the translations
    this._fuseTranslationLoaderService.loadTranslations(english, italian);

    this.title = this._data.title;
    this.message = this._data.message;
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.matDialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.matDialogRef.close(false);
  }

}
