export const locale = {
    lang: 'en',
    data: {
        'TOOLBAR': {
            'PROFILE': {
                'LOGOUT': 'Logout',
                'MY_PROFILE': 'My Profile'
            }
        }
    }
};
