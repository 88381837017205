import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { Cart } from 'app/common/_models/cart/cart';

@Injectable()
export class ToolbarService {

    navigation: any;

    currentCart = new BehaviorSubject<Cart>(null);

    constructor(
      private _fuseNavigationService: FuseNavigationService,
    ) {

    }

    reloadCurrentCart(cart: Cart) {
        this.currentCart.next(cart);

        this._fuseNavigationService.removeNavigationItem('cart');

        if (cart != null && cart.id != null && cart.status == 'DRAFT') {

            if (cart?.items != null && !cart?.deleted)
            {
                const newNavItem = {
                    id      : 'cart',
                    title   : '',
                    type    : 'item',
                    icon    : 'shopping_cart',
                    url     : '/apps/cart',
                    badge: {
                        title: '' + cart.itemsNumber != null ? cart.itemsNumber : 0,
                        bg   : '#c4ad75',
                        fg   : '#FFFFFF'
                    }
                };

                // Add the new nav item at the beginning of the navigation
                this._fuseNavigationService.addNavigationItem(newNavItem, 'start');

            }
            else
            {
                const newNavItem = {
                    id      : 'cart',
                    title   : '',
                    type    : 'item',
                    icon    : 'shopping_cart',
                    url     : '/apps/cart'
                };

                // Add the new nav item at the beginning of the navigation
                this._fuseNavigationService.addNavigationItem(newNavItem, 'start');
            }
        }
        else
        {
            const newNavItem = {
                id      : 'cart',
                title   : '',
                type    : 'item',
                icon    : 'shopping_cart',
                url     : '/apps/cart'
            };

            // Add the new nav item at the beginning of the navigation
            this._fuseNavigationService.addNavigationItem(newNavItem, 'start');
        }
    }
}
