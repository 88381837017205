import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { CustomLoaderService } from 'app/common/services/custom-loader.service';
@Injectable()
export class CustomLoaderInterceptor implements HttpInterceptor {
    constructor(public loaderService: CustomLoaderService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!req?.url?.includes("auto-reload-version.json"))
        {
          this.loaderService.show();
          return next.handle(req).pipe(
              finalize(() => this.loaderService.hide())
          );
        }
        else
        {
          return next.handle(req);
        }
    }
}
