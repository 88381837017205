import { ApiResponse } from './../_models/api-response';
import { HttpClient, HttpParams, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { BackendApiService } from './backend-api.service';
import { Observable, empty } from 'rxjs';
import { throwError } from 'rxjs';
import { BadInput } from '../errors/bad-input';
import { NotFoundError } from '../errors/not-found-error';
import { AppError } from '../errors/app-error';
import { map } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';

export class DataService extends BackendApiService {

  /**
   * Data Service constructor
   * @param {HttpClient} http HttpClient
   * @param {string} url URL of API to call (prepended by /)
   */
  constructor(http: HttpClient, url: string) {
    super(http, url);
  }

  getAll(params?: HttpParams, path?: string) {
    let serviceApiUrl = this._serviceApiUrl;
    if (path) {
      serviceApiUrl = serviceApiUrl + path;
    }

    if (params) {
      return this.http.get<ApiResponse>(serviceApiUrl, { params: params })
        .pipe(catchError(this.handleError));
    }

    return this.http.get<ApiResponse>(serviceApiUrl)
      .pipe(catchError(this.handleError));
  }

  getList(
    sort: string = 'id',
    order: string = 'desc',
    page: number = 0,
    pageSize: number = 10,
    activeFilter: boolean = false,
  ) {

    // Pagination parameters
    let paginationParams = new HttpParams()
      .append('sort', sort)
      .append('direction', order)
      .append('page', "" + (page + 1))
      .append('pageSize', "" + pageSize);

    if (activeFilter)
      paginationParams = paginationParams.append('active', "" + activeFilter);

    return this.getAll(paginationParams)
      .pipe(
        map(res => res["data"])
      );
  }

  get(id) {
    return this.http.get(this._serviceApiUrl + '/' + id)
      .pipe(catchError(this.handleError));
  }

  getByPath(path) {
    return this.http.get(this._serviceApiUrl + '/' + path)
      .pipe(catchError(this.handleError));
  }

  getWithoutCatchError(id) {
    return this.http.get(this._serviceApiUrl + '/' + id)
      .pipe(catchError((error: HttpErrorResponse) => {
        return empty();
      }));
  }

  create(resource) {
    return this.http.post(this._serviceApiUrl + '/', resource)
      .pipe(catchError(this.handleError));
  }

  update(resource) {
    if (resource && resource.id) {
      return this.http.put(this._serviceApiUrl + '/' + resource.id, resource)
        .pipe(catchError(this.handleError));
    }

    return this.http.put(this._serviceApiUrl, resource)
      .pipe(catchError(this.handleError));
  }

  createCustom(path, resource) {
    return this.http.post(this._serviceApiUrl + '/' + path, resource)
      .pipe(catchError(this.handleError));
  }

  postCustom(path, resource) {
    if (resource && resource.id) {
      return this.http.post(this._serviceApiUrl + '/' + path, resource)
        .pipe(catchError(this.handleError));
    }

    return this.http.post(this._serviceApiUrl + '/' + path, resource)
      .pipe(catchError(this.handleError));
  }

  updateCustom(path, resource) {
    if (resource && resource.id) {
      return this.http.put(this._serviceApiUrl + '/' + path, resource)
        .pipe(catchError(this.handleError));
    }

    return this.http.put(this._serviceApiUrl + '/' + path, resource)
      .pipe(catchError(this.handleError));
  }

  delete(id, logic?) {
    return new Promise((resolve, reject) => {
      this.http.delete(this._serviceApiUrl + id + '?logic=' + logic)
        .pipe(catchError(this.handleError))
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  deleteMedia(id, mediaId) {
    return this.http.delete(this._serviceApiUrl + '/' + id + '/media/' + mediaId)
      .pipe(
        map(res => res["data"])
      )
      .pipe(catchError(this.handleError));
  }

  exportToExcel(search?: string, sort?: string, order?: string, columns?: string[], columnNames?: Map<string, string>) {
    return this.exportToExcelWithAdditionalUrl(null, search, sort, order, columns, columnNames);
  }

  exportToExcelWithAdditionalUrl(additionalUrl: string, search?: string, sort?: string, order?: string, columns?: string[], columnNames?: Map<string, string>) {
    let url = additionalUrl + 'export/excel';
    if (search) {
      url = url + '?search=' + search;
    }
    if (sort) {
      url = url + (search ? '&' : '?') + 'sort=' + sort + "," + order;
    }
    if (columns) {
      const index = columns.indexOf('select', 0);
      if (index > -1) {
        columns.splice(index, 1);
      }

      url = url + (search || sort ? '&' : '?') + 'columns=' + columns.join(',');
    }
    if (columnNames) {
      const namesMap = {};
      columnNames.forEach((val: string, key: string) => {
        namesMap[key] = val;
      });
      //url = url + (search || columns ? '&' : '?') + 'columnNames=' + namesMap;
    }

    return this.getFile(url);
  }

  importExcel(file, columns?: string[], columnNames?: Map<string, string>) {
    let url = 'import/excel';
    if (columns) {
      url = url + '?columns=' + columns.join(',');
    }
    if (columnNames) {
      const namesMap = {};
      columnNames.forEach((val: string, key: string) => {
        namesMap[key] = val;
      });
      //url = url + (search || columns ? '&' : '?') + 'columnNames=' + namesMap;
    }

    return new Promise((resolve, reject) => {
      this.postFile(file, url, 'importFile')
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }


  /**
   * Post single file to url bound to the service
   * @param {File} fileToUpload
   * @param {string} additionalUrl url to add to service's base path
   * @param {string} binaryName name of file to post
   */
  postFile(fileToUpload: File, additionalUrl?: string, binaryName?: string): Observable<any> {
    const binaryNameToPost = binaryName || 'file';
    const formData: FormData = new FormData();
    formData.append(binaryNameToPost, fileToUpload, fileToUpload.name);

    let endPoint = this._serviceApiUrl;
    if (additionalUrl)
      endPoint += additionalUrl;

    return this.http
      .post(endPoint, formData)
      .pipe(map(res => res["data"]))
      .pipe(catchError(this.handleError));
  }


  getFile(additionalUrl?: string, params?: HttpParams) {
    let endPoint = this._serviceApiUrl;
    if (additionalUrl)
      endPoint += additionalUrl;

    if (params) {
      return this.http.get(endPoint, { params: params, observe: 'response', responseType: 'blob' as 'json' })
        .pipe(catchError(this.handleError))
        .subscribe(
          (response: HttpResponse<Blob>) => {
            let filename: string = this.getFileName(response)
            let binaryData = [];
            binaryData.push(response.body);
            let downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
            downloadLink.setAttribute('download', filename);
            document.body.appendChild(downloadLink);
            downloadLink.click();
          }
        )
    }

    return this.http.get(endPoint, { observe: 'response', responseType: 'blob' as 'json' })
      .pipe(catchError(this.handleError))
      .subscribe(
        (response: HttpResponse<Blob>) => {
          let filename: string = this.getFileName(response)
          let binaryData = [];
          binaryData.push(response.body);
          let downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
          downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          downloadLink.click();
        }
      )
  }

  private getFileName(response: HttpResponse<Blob>) {
    let filename: string;
    try {
      const contentDisposition: string = response.headers.get('Content-Disposition');
      filename = contentDisposition.split("filename=")[1].replace(/"/g, "");
    }
    catch (e) {
      console.error(e);
      filename = 'myfile.txt'
    }
    return filename
  }

  protected handleError(error: Response) {
    if (error.status === 400)
      return throwError(new BadInput(error.json()));

    if (error.status === 404)
      return throwError(new NotFoundError());

    return throwError(new AppError(error));
  }

  protected getDateInMilliseconds(date: Date): number {
    if (date) {
      return date.getTime();
    }

    return 0;
  }
}
