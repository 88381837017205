export const locale = {
  lang: 'en',
  data: {
    'NAVIGATION': {
      'VALGARDA': {
        'TITLE': 'My Area Valgarda',
        'CART': {
          'TITLE': 'Carrello'
        },
        'TASKS': {
          'TITLE': 'RAPPORTI INTERVENTO'
        },
        'ORDERS': {
          'TITLE': 'RIEPILOGO ORDINI'
        },
        'PRODUCTS': {
          'TITLE': 'PRODOTTI ORDINABILI'
        },
        'DOCUMENTS': {
          'TITLE': 'DOCUMENTAZIONE'
        }
      }
    }
  }
};
